import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import BackButton from './BackButton/BackButton'
import AtodButton from './AtodButton'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { Alert } from '@mui/material'
import { useHistory } from 'react-router-dom'
import useTeacherState from '../utils/useTeacherContext'
import Instance from '../utils/axios'
import AtodTextInput from './AtodTextInput'
import { validateName, validateEmail } from '../utils/validation'
import { Button } from '@mui/material'
import TermsAndConditions from '../assets/Constant/TermsConditions'

const AtodModal = ({ open, onClose, modalBtn }) => {
  
  const { teacherState, teacherDispatch } = useTeacherState()

  const [ errorMessage, setErrorMessage ] = useState(null)
  const [ succeededMessage, setSucceededMessage ] = useState(null)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ imgFile, setImgFile ] = useState(null)

  const [ newName, setNewName ] = useState(null)
  const [ newEmail, setNewEmail ] = useState(null)

  const [ cardName, setCardName ] = useState(null)
  const stripe = useStripe()
  const elements = useElements()

  const history = useHistory()

  const updateCreditInfo = async () => {
    if (!stripe || !elements) return

    setIsLoading(true)
    setErrorMessage(null)
    setSucceededMessage(null)

    try {
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          name: cardName
        }
      })

      const ourResult = await Instance.post('/teacherStripe/updatePaymentMethod', {
        pm_id: result.paymentMethod.id
      })

      setSucceededMessage('Credit card information has been processed')
      setTimeout(() => {
        history.go(0)
      }, 3000)

    } catch (e) {
      console.log(e.response)
      setErrorMessage('There was an error processing your payment details, please double check your details and try again')
    } finally {
      setIsLoading(false)
    }

  }

  const cancelSubsc = async () => {
    setIsLoading(true)
    try {
      const result = await Instance.post('/teacherStripe/cancelSubscription')
      console.log(result)
      if (result.status === 200) {
        setTimeout(() => {
          history.go(0)
        }, 1000)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const userLogout = () => {
    localStorage.removeItem('ATODTeacherToken')
    teacherDispatch({ type: 'setTeacher', data: null })
    history.push({ pathname: '/login' })
  }

  const updateUserInfo = async () => {
    setIsLoading(true)
    let message
    if (newName) {
      message = validateName(newName)
      setErrorMessage({nameError: message})
    }
    if (newEmail) {
      message = validateEmail(newEmail)
      setErrorMessage({emailError: message})
    }

    const formData = new FormData()
    formData.append('fileData', imgFile)
    const awsUploadURL = await Instance.post('/file/aws_upload?type=avatar', formData)

    if (!message) {
      if (newEmail) {
        try {
          const emailExist = await Instance.get(`teacher/whetherExist?email=${newEmail}`, {newEmail})
            if (emailExist.status !== 200) {
              try {
                await Instance.put('/teacher/details', {
                  id: teacherState?.teacher?.id,
                  name: newName ? newName : teacherState?.teacher?.name,
                  email: newEmail ? newEmail : teacherState?.teacher?.email,
                  avatar: awsUploadURL.data
                })
              } catch (error) {
                console.log(error)
              } finally {
                setNewName('')
                setNewEmail('')
                onClose()
                setIsLoading(false)
              }
            }
        } catch (error) {
          console.log(error)
        }
      } else {
        try {
          await Instance.put('/teacher/details', {
            id: teacherState?.teacher?.id,
            name: newName ? newName : teacherState?.teacher?.name,
            email: newEmail ? newEmail : teacherState?.teacher?.email,
            avatar: awsUploadURL.data
          })
        } catch (error) {
          console.log(error)
        } finally {
          setNewName('')
          setNewEmail('')
          onClose()
          setIsLoading(false)
        }
      }
    }
    setIsLoading(false)
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          outline: 'none'
        }}
      >
        <Box sx={modalBtn === 'terms_condition' ? modalStyle.termsBox : modalStyle.modalBox}>
          <div>
            <BackButton smaller='smaller' onClick={onClose}/>
            { modalBtn === 'creditCard' &&
              <>
                <div style={modalStyle.modalTitle}>Update Card</div>
                <div style={modalStyle.modalDesc}>Update your preferred payment method details for you ATOD Studio account.</div>
                <div style={modalStyle.creditCardInput}>
                  <input style={modalStyle.cardholder} placeholder='Card Holder Name' onChange={(e) => setCardName(e.target.value)}/>
                  <CardElement 
                    options={CARD_ELEMENT_OPTIONS}
                    id='stripe_card_element'
                  />
                  {errorMessage && <Alert severity='error' sx={{padding: '0 1em'}}>{errorMessage}</Alert>}
                  {succeededMessage && <Alert severity='success' sx={{padding: '0 1em', marginTop: '0.3em'}}>{succeededMessage}</Alert>}
                </div>
                <AtodButton text='Save' onClick={updateCreditInfo} isLoading={isLoading}/>
              </>
            }
            { modalBtn === 'cancel' &&
              <>
                <div style={modalStyle.modalTitle}>Are you sure you want to cancel your subscription.</div>
                <div style={modalStyle.modalDesc}>By cancelling your subscription you will no longer have access to the genre packs after your paid for term ends.</div>
                {/* <div style={modalStyle.modalDesc}>Access period ending: <span style={modalStyle.accessPeriod}>11-11-21</span></div> */}
                <AtodButton text='Cancel Subscription' margin='20px 0 0 0' bg='#C71C27' hc='#C71C27' onClick={cancelSubsc} isLoading={isLoading}/>
              </>
            }
            { modalBtn === 'logout' &&
              <>
                <div style={modalStyle.termsContainer}>
                  <div style={modalStyle.modalTitle}>Are you sure you want to log out of your ATOD Account</div>
                  <div style={modalStyle.modalDesc}>If you log out of your ATOD on the web account you will need to log back in to access the content again.</div>
                  <AtodButton text='Logout' margin='20px 0 0 0' bg='#E8323E' hc='#E8323E' onClick={userLogout}/>
                </div>
              </>
            }
            { modalBtn === 'terms_condition' &&
              <>
                <div style={modalStyle.modalTitle}>ATOD@Work Terms &amp; Conditions</div>
                <div style={modalStyle.termsCondition}>
                  {TermsAndConditions()}
                </div>
              </>
            }
            { modalBtn === 'updateAccount' &&
              <>
                <div style={modalStyle.modalTitle}>Update User Image</div>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="raised-button-file"
                  multiple
                  type="file"
                  onChange={e => setImgFile(e.target.files[0])}
                />
                <label htmlFor="raised-button-file">
                  <Button variant="raised" component="span">
                    Upload File
                  </Button>
                </label> 
                <div style={modalStyle.modalTitle}>Update User Name</div>
                  <AtodTextInput textInput={setNewName} 
                    label={teacherState?.teacher?.name} 
                    width='20em' marginBottom='1em' 
                    placeholder='New User Name'
                    errorMessage={errorMessage?.nameError}
                  />
                <div style={modalStyle.modalTitle}>Update User Email</div>
                  <AtodTextInput textInput={setNewEmail} 
                    label={teacherState?.teacher?.email} 
                    width='20em' marginBottom='1em' 
                    placeholder='New User Email'
                    errorMessage={errorMessage?.emailError}
                  />
                <AtodButton text='Save' onClick={updateUserInfo} isLoading={isLoading}/>
              </>
            }
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default AtodModal

const modalStyle = {
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    background: '#FFFFFF',
    boxShadow: 24,
    padding: '1em 2em',
    borderRadius: '10px',
  },
  termsBox: {
    position: 'relative',
    width: '50em',
    height: '85%',
    background: '#FFFFFF',
    boxShadow: '24',
    padding: '1em 2em',
    borderRadius: '10px',
    margin: '0 auto',
    marginTop: '5em',
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  modalTitle: {
    color: '#2066B2',
    fontWeight: 'bold',
    fontSize: '1.4em'
  },
  modalDesc: {
    fontSize: '0.8em',
    margin: '5px 0',
  },
  creditCardInput: {
    width: '100%',
    background: '#F0F0F0',
    borderRadius: '10px',
    border: 'none',
    margin: '1em 0',
  },
  cardNumber: {
    height: '100%',
    width: '60%',
    border: 'none',
    background: 'none',
    paddingLeft: '8px',
  },
  cardInfo: {
    height: '100%',
    width: '20%',
    border: 'none',
    background: 'none',
    paddingLeft: '8px',
  },
  accessPeriod: {
    fontWeight: 'bold'
  },
  termsCondition: {
    marginTop: '0.5em'
  },
  cardholder: {
    height: '2.3em',
    width: '50%',
    background: '#F0F0F0',
    borderRadius: '5px',
    margin: '0.5em 0',
    border: 'none',
    padding: '0 1em',
    fontSize: '0.9em'
  }
}

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: "#32325d",
      fontSmoothing: "antialiased",
      fontSize: "3.7vw",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
  rules: {
      '.Input': {
          width: '100px',
          fontSize: "3em",
      },
  },
}