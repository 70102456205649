import { useState } from 'react'
import { ReactComponent as ChevronLeft } from '../../assets/Icons/ChevronLeft.svg'
import { Link } from 'react-router-dom'
import './BackButton.css';

const BackButton = ( props ) => {
    const [ isHovered, setIsHovered ] = useState(false);

    const mouseEnter = () => {
        setIsHovered(true)
    }

    const mouseLeave = () => {
        setIsHovered(false)
    }

    const backPage = () => {
        props.input && props.activeInput('')
        props.bool && props.activeBool(!props.bool)
        props.genrePack && props.genrePack({type: 'setCheckout', data: false})
        props.onClose && props.onClose()
    }

    return (
        <>
            {props.link
            ?   
                <Link to={props.link}>
                    <div 
                    onMouseEnter={mouseEnter} 
                    onMouseLeave={mouseLeave} 
                    className='back_button_container' 
                    onClick={backPage} 
                    style={{color: props.title ? '#1D1D1D' : '#3166B2', margin: props.smaller ? '10px 0' : '0 0 5px 10px'}}
                    >
                        <ChevronLeft className='back_button_icon_style' style={{transform: isHovered ? 'translateX(-15px)' : `translateX(0)`}} />
                        <p style={{margin: '0 10px'}}>{props.title? props.title : 'Back'}</p>
                    </div>
                </Link>
            :
                <div onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} className='back_button_container' onClick={props.onClick} style={{color: props.title ? '#1D1D1D' : '#3166B2', margin: props.smaller ? '10px 0' : '0 0 5px 10px'}}
                >
                    <ChevronLeft className='back_button_icon_style' style={{transform: isHovered ? 'translateX(-15px)' : `translateX(0)`}} />
                    <p className='back_button_text_style'>{props.title? props.title : 'Back'}</p>
                </div>
            }
        </>
    )
}

export default BackButton
