import { useState, useEffect } from 'react'
import './Auth.css'
import MainImg from '../../assets/Images/Main_Img.png'
import BackButton from '../../components/BackButton/BackButton'
import AtodTextInput from '../../components/AtodTextInput'
import AtodButton from '../../components/AtodButton'
import ReactCodeInput from 'react-verification-code-input'
import { useHistory } from 'react-router-dom'
import Instance from '../../utils/axios'
import { validateUpdatePassword } from '../../utils/validation'

const RetrivePassword = () => {

    const [ email, setEmail ] = useState('')
    const [ authCode, setAuthCode ] = useState('')
    const [ newPassword, setNewPassword ] = useState('')
    const [ newConfirmPassword, setNewConfirmPassword ] = useState('')
    const [ emailSubmit , setEmailSubmit ] = useState(false)
    const [ authCodeSubmit, setAuthCodeSubmit ] = useState(false)
    const [ errorMessage, setErrorMessage ] = useState('')
    const [ validationMessage, setValidationMessage ] = useState({})
    const [ isLoading, setIsLoading ] = useState(false)
    const [ submit, setSubmit ] = useState(false)

    const [JWT, setJWT] = useState(null)
    const history = useHistory()

    const inputPassword = async () => {
        setIsLoading(true)
        try {
            if (email) {
                const emailExist = await Instance.get(`teacher/whetherExist?email=${email}`)
                if (emailExist.status === 200) {
                    try {
                        await Instance.get(`teacher/getVerificationCode?email=${email}`)
                        setEmailSubmit(true)
                    } catch (error) {
                        setErrorMessage('There are some errors, please try again later')
                        console.log(error.response)
                    }
                }
                setErrorMessage('Email you input does not exist')
            } else {
                setErrorMessage('Please enter your email')
            }
        } catch (error) {
            setErrorMessage('Email you input does not exist')
        } finally {
            setIsLoading(false)
        }
    }

    const sendAuthCode = async () => {
        try {
            setIsLoading(true)
            const { data } = await Instance.post('teacher/verifyCode', {
                email,
                code: authCode,
            })
            setJWT(data.JWT.token)
            setAuthCodeSubmit(true)
        } catch (error) {
            console.log('this is error from verifyCode: ', error)
            alert('Incorrect auth code')
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (!submit) return
        validateInput()
    },[ newPassword, newConfirmPassword, submit ])

    const validateInput = () => {
        setSubmit(true)
        const errorMessages = validateUpdatePassword(newPassword, newConfirmPassword)
        setValidationMessage(errorMessages)
        return errorMessages
    }

    const updateNewPassword = async () => {
        setIsLoading(true)
        const message = validateInput()
        if (message !== 'clear') return

        try {
            await Instance.put('teacher/updatePassword', {
                email,
                newPassword
            }, { headers: { Authorization: `Bearer ${JWT}` }})

            alert('Your password has been successfully updated')
            history.push({ pathname: 'login' })
        } catch (error) {
            console.log('this is error: ', error.response)
            alert('There was an error updating your password, please try again')
        } finally {
            setIsLoading(false)
        }
    }

    return(
        <div className='min_width_wrapper'>
            <img img={MainImg}/>
            <div className='right_container'>
                <div className='centralizing'>
                    {!emailSubmit && !authCodeSubmit && 
                        <>
                            <BackButton smaller='smaller' link='login'/>
                            <div className='main_title'>Retrieve Password</div>
                            <h3 className='section_title'>Account Email</h3>
                            <p className='section_instruction'>Enter your account email associated with the password</p>
                            <AtodTextInput type='email' label='Email' placeholder='John@example' textInput={setEmail} errorMessage={errorMessage}/>
                            <AtodButton text='Next' margin='30px 0' onClick={inputPassword} isLoading={isLoading}/>
                        </>
                    }
                    {emailSubmit && !authCodeSubmit &&
                        <>
                            <BackButton smaller='smaller' onClick={() => setEmailSubmit(!emailSubmit)}/>
                            <div className='main_title'>Retrive Password</div>
                            <h3 className='section_title'>Authentication Code</h3>
                            <p className='section_instruction'>Enter the authentication code sent to </p>
                            <p className='input_email'>{email}</p>
                            <ReactCodeInput 
                                className='auth_code_input'
                                onChange={(value) => setAuthCode(value)}
                            />
                            <AtodButton text='Next' margin='50px 0' onClick={sendAuthCode}/>
                        </>
                    }
                    {emailSubmit && authCodeSubmit &&
                        <>
                            <BackButton smaller='smaller' bool={authCodeSubmit} activeBool={setAuthCodeSubmit} />
                            <div className='main_title'>Update Password</div>
                            <h3 className='section_title'>New Password</h3>
                            <p className='section_instruction'>Enter your new ATOD Studio password.</p>
                            <AtodTextInput 
                                type='password' 
                                label='Password' 
                                placeholder='Enter' 
                                textInput={setNewPassword} 
                                errorMessage={validationMessage.passwordMessage}
                            />
                            <AtodTextInput 
                                type='password' 
                                label='Confirm Password' 
                                placeholder='Enter' 
                                textInput={setNewConfirmPassword}
                                errorMessage={validationMessage.passwordConfirmMessage}
                            />
                            <AtodButton 
                                text='Next' 
                                margin='50px 0' 
                                isLoading={isLoading}
                                onClick={updateNewPassword}
                            />
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default RetrivePassword
