import '../../App.css'

const TermsAndConditions = () => {
    return(
        <>
            <div>
                <ol className='tnc_ol'>
                   <li>
                    Copyright- 2022 Australian Teachers of Dancing Ltd (ATOD) ACN 051 244 510. This material is copyright. Other than for the purposes of and subject to the conditions prescribed under the Copyright Act, no part of it may in any form or by any means (electronic, mechanical, micro copying, recording or otherwise) be reproduced, stored in a retrieval system or transmitted without prior written permission of the copyright owner.
                    </li> 
                    <br/>
                    <li>
                    This teaching resource is for the exclusive use of financial ATOD members. Non- members may trial the platform for a period of 14 days however access will then be restricted until they become a financial member. If we discover you are not a financial member at any time while using this platform ATOD reserves the right to immediately disconnect your access until your financial status has been resolved. For information about becoming a member, please email office@atod.net.au
                    </li>
                    <br/>
                    <li>
                    Examination and Participation assessments are offered in all genres. Please refer to the ATOD Teachers Manual for all examination requirements.
                    </li>
                    <br/>
                    <li>
                    Subscription Payments – All users receive a 14-day free trial period from the date that they select their first package and enter their credit card details. After 14 days the payment portal will attempt to process the first payment in line with the package selected and the payment timing chosen. In the case of a payment being rejected for any reason, access to the platform will be restricted until the payment has successfully processed. ATOD accepts no responsibility for the inconvenience caused to you or your teachers from not having access to the platform in the instance of a rejected payment.
                    </li>
                    <br/>
                    <li>
                    Liability waiver, release and indemnity
                    </li>
                    <br/>
                </ol>
                <p>
                    This information is provided by Australian Teachers of Dancing Limited (“ATOD”). ATOD requires the user of this resource platform to read, understand and agree to be bound by the below conditions before using the resource platform.
                </p>
                <br/>
                <p>
                    I acknowledge, understand and warrant that:
                </p>
                <br/>
                <ol type='1' className='tnc_ol_second'>
                    <li>
                        I am the user of this application and I have spoken to all dance teachers who I employ that use this platform about these terms and conditions.
                    </li>
                    <li>
                        In using the platform to inform my dance instruction, I acknowledge there is a real risk of injury that could possibly be serious. This includes injuries that may be related to or resulting from pre-existing disabilities.
                    </li>
                    <li>
                        It is not possible to list all the potential injuries or their possible causes from the use of the application. Injury may be unforeseen, accidental, or preventable. Although it is uncommon, the injury may be serious. I acknowledge that ATOD cannot prevent all injuries associated with the use of the platform. Injury might be caused by a failure to follow instructions, a failure to wear protective and appropriate footwear, carelessness, the negligence of other uses and/or other causes.
                    </li>
                    <li>
                        In using this platform for the purpose of dance instruction, I accept all risks which could result in personal injury (whether that injury is temporary or permanent) or the loss of life associated with its use. I release and hold harmless ATOD, its directors and employees for all liability arising from injury, loss or damage arising out of or connected with the use of the platform for the purpose of dance instruction. This release and indemnity continues forever and bind my heirs, executors, personal representatives and assigns.
                    </li>
                    <li>
                        I also accept all liability for any property damage arising out of or connected with my use of the platform for the purpose of dance instruction. I acknowledge this includes loss or damage related to my property or equipment, as well as damage to another person’s property or equipment as a consequence of my activities and use of the platform for dance instruction. I release and indemnify ATOD, its directors and employees for all liability for any damage to property and equipment arising out of, or connected with, my use of the platform for dance instruction. This release and indemnity continues forever and binds my heirs, executors, personal representatives and assigns.
                    </li>
                </ol>
                <p>
                    I certify that I am 18 years of age or older and that I have read this document and fully
                    understand it and agree to abide by the terms referred to above. I also acknowledge
                    that I am not able to use the platform until such time as this Liability Waiver, Release
                    and Indemnity Form is agreed to.
                </p>
            </div>
        </>
    )
}

export default TermsAndConditions