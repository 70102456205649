export default function reducer(state, action) {
    switch (action.type) {
        case 'setTeacher': {
            return {
                ...state,
                teacher: action.data
            }
        }
        default:
            return state
    }
}