
export const loginEmail = (input) => {
    if (input.length === 0) return 'Please enter your email in format: sample@atod.net.au' 
    else return ''
}

export const validateName = (inputName) => {
    const letters = /^[A-Za-z ]+$/
    if (inputName === null || inputName === '') return "Please enter your name"
    if (!inputName.match(letters))return 'Alphabet only'
    else return ''
}

export const validateEmail = (input) => {
    let email = input
    if (email.length === 0) return 'Please enter your email'
    if (!/^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z.0-9]+$/.test(email)) return 'Please enter your email in format: sample@atod.net.au'
    else return ''
}

const validatePassword = (input) => {
    let passwordInput = input
    if (passwordInput.length === 0) return 'Please enter your password'
    if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(passwordInput)) return 'Please enter a password which contains 8 characters, a number and a special character'
    else return ''
}

const validateConfirmPassword = (inputPassword, inputConfirmPassword) => {
    if (!inputPassword) return 'Confirm Password cannot be blank'
    if (inputPassword !== inputConfirmPassword) return 'Password confirmation and Password must match.'
    else return ''
}

export const validateForm = (name, email, password, confirmPassword) => {
    const nameMassge = validateName(name)
    const emailMessage = validateEmail(email)
    const passwordMessage = validatePassword(password)
    const passwordConfirmMessage = validateConfirmPassword(password, confirmPassword)
    if(!nameMassge && !emailMessage && !passwordMessage && !passwordConfirmMessage) return 'clear'
    return {
        nameMassge: nameMassge,
        emailMessage: emailMessage,
        passwordMessage: passwordMessage,
        passwordConfirmMessage: passwordConfirmMessage,
    }
}

export const validateUpdatePassword = (password, confirmPassword) => {
    const passwordMessage = validatePassword(password)
    const passwordConfirmMessage = validateConfirmPassword(password, confirmPassword)
    if(!passwordMessage && !passwordConfirmMessage) return 'clear'
    return {
        passwordMessage: passwordMessage,
        passwordConfirmMessage: passwordConfirmMessage,
    }
}