import { useEffect, useState } from 'react'
import './Auth.css'
import Instance from '../../utils/axios'
import useTeacherState from '../../utils/useTeacherContext'
import { validateForm } from '../../utils/validation'
import { useHistory } from 'react-router-dom'
import AtodButton from '../../components/AtodButton'
import AtodTextInput from '../../components/AtodTextInput'
import BackButton from '../../components/BackButton/BackButton'
import MainImg from '../../assets/Images/Main_Img.png'
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt'
import AtodModal from '../../components/AtodModal'
import TermsConditions from '../../assets/TermsConditions/sample.pdf'

const Signup = () => {
    const { teacherDispatch } = useTeacherState()
    const [ name, setName ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ confirmPassword, setConfirmPassword ] = useState('')
    const [ isLoading, setIsLoading ] = useState(false)
    const [ validationMessage, setValidationMessage ] = useState({})
    const [ submit, setSubmit ] = useState(false)
    const [ open, setOpen ] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const history = useHistory()

    useEffect(() => {
        if (!submit) return
        validateInput()
    },[ name, email, password, confirmPassword, submit ])

    const validateInput = () => {
        setSubmit(true)
        const errorMessages = validateForm(name, email, password, confirmPassword)
        setValidationMessage(errorMessages)
        return errorMessages
    }

    const downloadPDF = () => {
        var FileSaver = require('file-saver');
        FileSaver.saveAs(TermsConditions, "terms_conditions.pdf");
    }

    const signUpUser = async () => {
        setIsLoading(true)
        const message = validateInput()
        if (message === 'clear') {
            try {
                const emailExist = await Instance.get(`teacher/whetherExist?email=${email}`, {email})
                if (emailExist.status !== 200) {
                    try {
                        const result = await Instance.post('/teacher/signup', {
                            name,
                            email,
                            password
                        })
                        if (result.status === 200){
                            localStorage.setItem('ATODTeacherToken', result.data.JWT.token)
                            teacherDispatch({type: 'setTeacher', data: result.data.teacher})
                            history.push({ pathname: '/genre_packs' })
                        }
                    } catch (e) {
                        setIsLoading(false)
                        alert('An unknown error has occured')
                        history.push({ pathname: '/signup' })
                    }
                }
            } catch (error) {
                setValidationMessage({email: 'This Email is already in use'})
                setIsLoading(false)
            }
        }
        setIsLoading(false)
    }

    return (
        <div className='min_width_wrapper'>
            {/* <AtodImg img={MainImg}/> */}
            <img src={MainImg} />
            <div className='right_container'>
                <div className='centralizing'>
                    <BackButton smaller='smaller' link='login'/>
                    <div className='main_title'>Your Details</div>
                        <h3 className='section_title'>About You</h3>
                            <AtodTextInput 
                                type='text' 
                                label='Name' 
                                placeholder='John' 
                                textInput={setName} 
                                errorMessage={validationMessage.nameMassge}
                            />
                            <AtodTextInput 
                                type='email' 
                                label='Email' 
                                placeholder='sample@atod.net.au' 
                                textInput={setEmail}
                                errorMessage={validationMessage.emailMessage}
                            />
                    <div className='border_style'></div>
                        <h3 className='section_title'>Security</h3>
                            <p className='section_instruction'>Ensure you create a secure password for you account.</p>
                            <AtodTextInput 
                                type='password' 
                                label='Password' 
                                placeholder='Enter' 
                                textInput={setPassword}
                                errorMessage={validationMessage.passwordMessage}
                            />
                            <AtodTextInput 
                                type='password' 
                                label='Confirm Password' 
                                placeholder='Enter'
                                textInput={setConfirmPassword} 
                                errorMessage={validationMessage.passwordConfirmMessage}
                            />
                    <div className='border_style'></div>
                        <h3 className='section_title'>Terms &amp; Conditions</h3>
                        <p className='section_instruction'>By progressing to sign up to ATOD@Work you understand the terms and conditions laid out in the ATOD terms and conditions document below.</p>
                        <div className='pdf_container'>
                            <div className='pdf_file' onClick={handleOpen}>ATOD@Work Terms &amp; Conditions.PDF</div>
                            <SystemUpdateAltIcon onClick={downloadPDF} className='download_icon'/>
                        </div>
                        <AtodButton 
                            text='Next'
                            isLoading={isLoading}
                            onClick={signUpUser}
                        />
                        <AtodModal 
                            open={open}
                            onClose={handleClose}
                            modalBtn='terms_condition'
                        />
                </div>
            </div>
        </div> 
    )
}

export default Signup